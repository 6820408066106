import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Vant from 'vant'
import 'vant/lib/index.css'

import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard)
Vue.use(Vant)



Vue.config.productionTip = false

//后台地址接口
// Vue.prototype.$baseURL = 'http://part.com'
Vue.prototype.$baseURL = 'https://rxind.work'

import flexible from '../public/flexible'

new Vue({
  flexible,
  router,
  render: h => h(App),
}).$mount('#app')



router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})