import Vue from "vue"
import Router from "vue-router"
import { Dialog } from "vant";

Vue.use(Router)
Vue.use(Dialog)
export default new Router({
    mode:"history",
    base:process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if(savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
    routes:[{
        path:'/',
        name:'Index',
        component :()=> import('@/pages/indexPage.vue'),
        title:'首页',
        meta:{
            keepAlive: true,
            title:'首页'
        }
    },{
        path:'/login',
        name:'login',
        component :()=> import('@/pages/loginPage.vue'),
        title:'授权登录',
        meta:{
            title:'授权登录'
        }
    },{
        path:'/mine',
        name:'Mine',
        component :()=> import('@/pages/minePage.vue'),
        meta:{
            title:'我的'
        }
    },{
        path:'/detail',
        name:'Detail',
        component :()=> import('@/pages/detailPage.vue'),
        meta:{
            title:'详情'
        }
    },{
        path:'/join',
        name:'Join',
        component :()=> import('@/pages/joinPage.vue'),
        meta:{
            title:'加入社群'
        }
    },{
        path:'/invome',
        name:'invome',
        component :()=> import('@/pages/invomePage.vue'),
        meta:{
            title:'收益详情'
        }
    },{
        path:'/approve',
        name:'approve',
        component :()=> import('@/pages/approvePage.vue'),
        meta:{
            title:'实名认证'
        }
    },{
        path:'/partner',
        name:'partner',
        component :()=> import('@/pages/partnerPage.vue'),
        meta:{
            title:'伙伴计划'
        }
    },{
        path:'/release',
        name:'release',
        component :()=> import('@/pages/releasePage.vue'),
        meta:{
            title:'发布套餐'
        }
    },{
        path:'/person',
        name:'person',
        component :()=> import('@/pages/personPage.vue'),
        meta:{
            title:'加入人员'
        }
    },{
        path:'/posts',
        name:'posts',
        component :()=> import('@/pages/postPage.vue'),
        meta:{
            title:'发布信息'
        }
    },{
        path:'/send',
        name:'send',
        component :()=> import('@/pages/sendPage.vue'),
        meta:{
            title:'发布套餐'
        }
    },{
        path:'/message',
        name:'message',
        component :()=> import('@/pages/messagePage.vue'),
        meta:{
            title:'信息列表'
        }
    },{
        path:'/messageDetail',
        name:'messageDetail',
        component :()=> import('@/pages/messageDetail.vue'),
        meta:{
            title:'信息详情'
        }
    },{
        path:'/subscribe',
        name:'subscribe',
        component :()=> import('@/pages/subscribePage.vue'),
        meta:{
            title:'订阅管理'
        }
    },{
        path:'/blocked',
        name:'blocked',
        component :()=> import('@/pages/blockedPage.vue'),
        meta:{
            title:'错误'
        }
    }]
})


const originalPush = Router.prototype.push;
const originalReplace = Router.prototype.replace;

// push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalPush.call(this, location, onResolve, onReject);
    return originalPush.call(this, location).catch(err => err);
};

//replace
Router.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};